import {
  FETCH_PQRDATA_SAGA,
  PQRDATA_LOADER_START,
  PQRDATA_LOADER_STOP,
  SET_REPORT_GENERATED_INFO_DATA_LIST,
  FETCH_PQRDATA_REPORT_LINK_SAGA,
} from "./types";

const initialState = {
  isPqrDataLoding: false,
  pqrdataList: [],
  reportGeneratedInfoList: [],
  pqrReportLinkData: [],
  boundary: 10,
};

const pqrdataReducer = (state = initialState, action) => {
  switch (action.type) {
    case PQRDATA_LOADER_START: {
      return {
        ...state,
        isPqrDataLoding: true,
      };
    }

    case FETCH_PQRDATA_SAGA: {
      return {
        ...state,
        pqrdataList: action.payload,
      };
    }

    case SET_REPORT_GENERATED_INFO_DATA_LIST: {
      return {
        ...state,
        reportGeneratedInfoList: action.payload,
      };
    }
    case FETCH_PQRDATA_REPORT_LINK_SAGA: {
      return {
        ...state,
        pqrReportLinkData: action.payload,
      };
    }

    case PQRDATA_LOADER_STOP: {
      return {
        ...state,
        isPqrDataLoding: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default pqrdataReducer;
