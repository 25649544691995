import { LeftOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { Button, Drawer, List, notification, Table } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { logDataHelperFunctions } from "../../helpers/logdata-helper";
import TlogTab from "../../layout/tlogTab";
import TlogTableView from "../TlogData/TlogTableView";

const TlogData = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const {
    stationNumbers,
    station_number,
    buyType,
    type,
    zone,
    weld_number,
    s_deviceid_event,
    weld_time,
    start_time,
    end_time,
    band_diameter,
    logging_distance,
    bug_number,
    lead_wire_diameter,
    trail_wire_diameter,
    pipe_diameter,
    job_number,
    gps,
    iwm,
  } = location.state || {};

  const { weld_no, Bugtype, deviceid, weldNumber, bugTypepqr, passn } =
    location.state || {};

  const queryParams = new URLSearchParams(location.search);
  const boundary = queryParams.get("boundary");

  const hasData = weld_no && Bugtype && deviceid;

  const reducer = useSelector((state) => state.tlogsReducer);
  const reducerList = useSelector((state) => state);
  const selectedProject = reducerList?.projectReducer?.selectedProject;

  const loader = reducer?.isTlogsLoading;
  const [activeTab, setActiveTab] = useState("TableView");

  const [columns] = useState([
    {
      title: t("tlogData.bugno"),
      dataIndex: "bugNumber",
      key: "bugNumber",
    },
    {
      title: `${
        selectedProject.weldAssignmentType == t("tlogData.gpsDeg")
          ? t("tlogData.gps")
          : t("tlogData.bandDiameter")
      }`,
      dataIndex: "gps",
      key: "gps",
    },
    {
      title: t("tlogData.wirediameter"),
      dataIndex: "wireDiameter",
      key: "wireDiameter",
    },
    {
      title: t("tlogData.loggingdistance"),
      dataIndex: "loggingDistance",
      key: "loggingDistance",
    },
    {
      title: t("tlogData.pipediameter"),
      dataIndex: "pipeDiameter",
      key: "pipeDiameter",
    },
    {
      title: t("tlogData.jobno"),
      dataIndex: "jobNumber",
      key: "jobNumber",
    },
    {
      title: t("tlogData.weldstarttime"),
      dataIndex: "weldStartTime",
      key: "weldStartTime",
    },
    {
      title: t("tlogData.Weldendtime"),
      dataIndex: "weldEndTime",
      key: "weldEndTime",
    },
    {
      title: t("tlogData.weldtime"),
      dataIndex: "weldTime",
      key: "weldTime",
    },
  ]);

  const data = loader
    ? [
        {
          key: 1,
          bugNumber: "--",
          gps: "--",
          wireDiameter: "--",
          loggingDistance: "--",
          pipeDiameter: "--",
          jobNumber: "--",
          weldStartTime: "--",
          weldEndTime: "--",
          weldTime: "--",
        },
      ]
    : [
        {
          key: 1,
          bugNumber: bug_number,
          gps:
            selectedProject.weldAssignmentType == "GPS"
              ? JSON.parse(gps ?? "{}")?.Latitude +
                ", " +
                JSON.parse(gps ?? "{}")?.Longitude
              : band_diameter,
          wireDiameter:
            type == "Lead" || station_number == "0"
              ? lead_wire_diameter
              : trail_wire_diameter,
          loggingDistance:
            station_number == "0"
              ? JSON.parse(iwm || "{}")?.Logging_period
              : logging_distance,
          pipeDiameter: pipe_diameter,
          jobNumber: job_number,
          weldStartTime: logDataHelperFunctions.formatWeldStartTime(
            start_time,
            false,
            false,
            true
          ),
          weldEndTime: logDataHelperFunctions.formatWeldStartTime(
            end_time,
            false,
            false,
            true
          ),
          weldTime: weld_time,
        },
      ];

  const tlogTableColumns = [
    {
      title: t("slogColumntitle.sl.no"),
      dataIndex: "sl_no",
      key: "sl_no",
      position: 0,
      width: 130,
    },
    {
      title: t("slogColumntitle.status"),
      dataIndex: "status",
      key: "status",
      position: 1,
      width: 130,
    },
    {
      title: t("slogColumntitle.event"),
      dataIndex: "event",
      key: "event",
      position: 2,
      width: 130,
    },
    {
      title: t("slogColumntitle.time"),
      dataIndex: "time",
      key: "time",
      position: 3,
      width: 130,
    },
    {
      title: t("slogColumntitle.tilt"),
      dataIndex: "tilt",
      key: "tilt",
      position: 4,
      width: 130,
    },
    {
      title: t("slogColumntitle.pass"),
      dataIndex: "pass",
      key: "pass",
      position: 5,
      width: 130,
    },
    {
      title: t("slogColumntitle.zone"),
      dataIndex: "zone",
      key: "zone",
      position: 6,
      width: 130,
    },
    {
      title: t("slogColumntitle.distance"),
      dataIndex: "distance",
      key: "distance",
      position: 7,
      width: 130,
    },
    {
      title: `${t("slogColumntitle.travelSpeed")} \n`,
      dataIndex: "travelSpeed",
      key: "travelSpeed",
      position: 8,
      width: 130,
    },
    {
      title: `${t("slogColumntitle.volt")} \n`,
      dataIndex: "volts",
      key: "volts",
      position: 9,
      width: 130,
    },
    {
      title: `${t("slogColumntitle.current")} \n`,
      dataIndex: "current",
      key: "current",
      position: 10,
      width: 130,
    },
    {
      title: `${t("slogColumntitle.wireSpeed")}`,
      dataIndex: "wireSpeed",
      key: "wireSpeed",
      position: 11,
      width: 130,
    },
    {
      title: t("slogColumntitle.oscillationWidth"),
      dataIndex: "oscillationWidth",
      key: "oscillationWidth",
      position: 12,
      width: 130,
    },
    {
      title: t("slogColumntitle.target"),
      dataIndex: "target",
      key: "target",
      position: 13,
      width: 130,
    },
    {
      title: t("slogColumntitle.horizontalBias"),
      dataIndex: "horizontalBias",
      key: "horizontalBias",
      position: 14,
      width: 130,
    },
    {
      title: t("slogColumntitle.frequency"),
      dataIndex: "frequency",
      key: "frequency",
      position: 15,
      width: 130,
    },
    {
      title: t("slogColumntitle.totalWireConsumed"),
      dataIndex: "totalWireConsumed",
      key: "totalWireConsumed",
      position: 16,
      width: 130,
    },
    {
      title: t("slogColumntitle.trueEnergy"),
      dataIndex: "trueEnergy",
      key: "trueEnergy",
      position: 17,
      width: 130,
    },
    {
      title: t("slogColumntitle.heat"),
      dataIndex: "heat",
      key: "heat",
      position: 18,
      width: 130,
    },
  ];

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [tlodVisibleColumns, setTlodVisibleColumns] =
    useState(tlogTableColumns);
  const [tlogRemovedColumns, setTlogRemovedColumns] = useState([]);
  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const removeColumn = (key, setVisible, setRemoved, visible, removed) => {
    if (visible.length <= 1) {
      notification.warning({
        message:
          "You cannot remove a column, at least one must remain in view.",
      });
      return;
    }
    const removedColumn = visible.find((col) => col.key === key);
    if (removedColumn) {
      const updatedColumns = visible.filter((col) => col.key !== key);
      setVisible(updatedColumns);
      setRemoved([...removed, removedColumn]);
    }
  };

  const addBackColumn = (key, setVisible, setRemoved, visible, removed) => {
    const addedColumn = removed.find((col) => col.key === key);
    if (addedColumn) {
      const updatedRemovedColumns = removed.filter((col) => col.key !== key);
      setRemoved(updatedRemovedColumns);

      // Find the index where the column should be added back
      let insertIndex = visible.findIndex(
        (col) => col.position > addedColumn.position
      );
      if (insertIndex === -1) {
        insertIndex = visible.length;
      }

      // Insert the column back at the correct position
      const updatedVisibleColumns = [
        ...visible.slice(0, insertIndex),
        addedColumn,
        ...visible.slice(insertIndex),
      ];
      setVisible(updatedVisibleColumns);
    }
  };

  const renderColumnOptions = (
    visibleColumns,
    removedColumns,
    setVisibleColumns,
    setRemovedColumns
  ) => (
    <List
      dataSource={[...visibleColumns, ...removedColumns]}
      renderItem={(item) => (
        <List.Item
          actions={[
            removedColumns.includes(item) ? (
              <Button
                key={`${item.key}-add`}
                onClick={() =>
                  addBackColumn(
                    item.key,
                    setVisibleColumns,
                    setRemovedColumns,
                    visibleColumns,
                    removedColumns
                  )
                }
              >
                Add Back
              </Button>
            ) : (
              <Button
                key={`${item.key}-remove`}
                onClick={() =>
                  removeColumn(
                    item.key,
                    setVisibleColumns,
                    setRemovedColumns,
                    visibleColumns,
                    removedColumns
                  )
                }
              >
                Remove
              </Button>
            ),
          ]}
        >
          {item.title}
        </List.Item>
      )}
    />
  );

  return (
    <div>
      <div className="slogweld-title">
        {!hasData ? (
          <>
            <LeftOutlined className="leftoutlined" />
            <Link to={"/data360-home"}>
              <h2 className="slogweld-header-active">Log Data</h2>
            </Link>
            <LeftOutlined className="leftoutlined" />
            <Link to={`/slog-weld/${weld_number}`}>
              <h2 className="slogweld-header-active">Weld {weld_number}</h2>
            </Link>
            <LeftOutlined className="leftoutlined" />

            <h2 className="slogweld-header">
              {stationNumbers} : {buyType}
              {type ? ` | ${type}` : ""}
              {zone ? ` : ${zone}` : ""}
            </h2>
          </>
        ) : (
          <>
            <LeftOutlined className="leftoutlined" />
            <Link to={"/data360-home/pqr-report"}>
              <h2 className="slogweld-header-active">PQR Report</h2>
            </Link>
            <LeftOutlined className="leftoutlined" />
            <Link to={`/pqr-table-data/${weldNumber}?boundary=${boundary}`}>
              <h2 className="slogweld-header-active">Weld {weldNumber}</h2>
            </Link>
            <LeftOutlined className="leftoutlined" />
            <h2 className="slogweld-header">{passn}</h2>
            <LeftOutlined className="leftoutlined" />
            <h2 className="slogweld-header">
              {weld_no} : {Bugtype == "iwm" ? "" : Bugtype + " : "} {bugTypepqr}
            </h2>
          </>
        )}
        {activeTab === "TableView" && (
          <Button
            key="cancel"
            type="secondary"
            className="column-option"
            onClick={showDrawer}
          >
            <MenuFoldOutlined />
            Column Options
          </Button>
        )}
      </div>
      <>
        {!hasData ? (
          <div className="tlog-detail-bar">
            <Table
              columns={columns
                ?.filter((ite) => {
                  if (
                    station_number == "0" &&
                    ite.dataIndex == "loggingDistance"
                  ) {
                    ite.title = "Logging Period";
                  }
                  return station_number == "0" && ite.dataIndex == "gps"
                    ? null
                    : ite;
                })
                .filter((ite) => ite != null)}
              dataSource={data}
              pagination={false}
            />
          </div>
        ) : null}
      </>
      {!hasData ? (
        <TlogTab
          weld_number={weld_number}
          zone={zone}
          type={type}
          s_deviceid_event={s_deviceid_event}
          station_number={station_number}
          tlodVisibleColumns={tlodVisibleColumns}
          setActiveTab={setActiveTab}
        />
      ) : (
        <TlogTableView
          weld_number={weld_number}
          type={type}
          zone={zone}
          s_deviceid_event={s_deviceid_event}
          station_number={station_number}
          tlodVisibleColumns={tlodVisibleColumns}
        />
      )}
      <Drawer
        title="Column Options"
        className="drawer"
        width={500}
        placement="right"
        closable={true}
        onClose={closeDrawer}
        open={drawerVisible}
      >
        {renderColumnOptions(
          tlodVisibleColumns,
          tlogRemovedColumns,
          setTlodVisibleColumns,
          setTlogRemovedColumns
        )}
      </Drawer>
    </div>
  );
};

export default TlogData;
