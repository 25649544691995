import {
  FETCH_PQRDATA_SAGA,
  PQRDATA_LOADER_START,
  PQRDATA_LOADER_STOP,
  SET_REPORT_GENERATED_INFO_DATA_LIST,
  FETCH_PQRDATA_REPORT_LINK_SAGA,
} from "./types";

const startPqrDataLoader = () => ({
  type: PQRDATA_LOADER_START,
});

const stopPqrDataLoader = () => ({
  type: PQRDATA_LOADER_STOP,
});

const setPqrDataList = (payload) => ({
  type: FETCH_PQRDATA_SAGA,
  payload,
});

const GetPqrReportLink = (payload) => ({
  type: FETCH_PQRDATA_REPORT_LINK_SAGA,
  payload,
});

const setReportGeneratedInfoDataList = (payload) => ({
  type: SET_REPORT_GENERATED_INFO_DATA_LIST,
  payload,
});

export {
  startPqrDataLoader,
  stopPqrDataLoader,
  setPqrDataList,
  setReportGeneratedInfoDataList,
  GetPqrReportLink,
};
