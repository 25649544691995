const FETCH_PQRDATA_SAGA = "@@pqrdata/fetchPqrDataSaga";
const FETCH_PQRDATA_REPORT_SAGA = "@@pqrdata/fetchPqrDataReportSaga";
const FETCH_PQRDATA_REPORT_LINK_SAGA = "@@pqrdata/fetchPqrDataReportLinkSaga";
const SET_REPORT_GENERATED_INFO_DATA_LIST =
  "@@pqrdata/setReportGeneratedInfoSaga";
const PQRDATA_LOADER_START = "@@pqrdata/startLoader";
const PQRDATA_LOADER_STOP = "@@pqrdata/stopLoader";

export {
  FETCH_PQRDATA_SAGA,
  PQRDATA_LOADER_START,
  PQRDATA_LOADER_STOP,
  FETCH_PQRDATA_REPORT_SAGA,
  FETCH_PQRDATA_REPORT_LINK_SAGA,
  SET_REPORT_GENERATED_INFO_DATA_LIST,
};
