import { Select, Table, InputNumber } from "antd";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startStatusLoader } from "../../redux/statusCalculation/actions";
import { FETCH_STATUS_CALCULATION_SAGA } from "../../redux/statusCalculation/types";
import CommonLoader from "../Loaders/loader";

const { Option } = Select;

const StatusConfiguration = () => {
  const dispatch = useDispatch();
  const StatusData = useSelector((state) => state.statusReducer);
  const tableLimitData = StatusData?.statusList?.allDataSet?.recordList;

  const Loader = StatusData?.isStatusLoading;

  const selectedProjectId = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_id
  );
  const selectedProjectName = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_name
  );
  const [selectedJobNumber, setSelectedJobNumber] = useState(null);

  useEffect(() => {
    if (tableLimitData) {
      transposedData.map((item) => ({
        ...item,
        checked:
          tableLimitData.find((dataItem) => dataItem.id === item.key)
            ?.checked || false,
      }));
    }
  }, [tableLimitData]);

  useEffect(() => {
    if (selectedProjectId && selectedProjectName) {
      const params = {
        skip: 0,
        limit: 100,
        project_id: selectedProjectId,
        project_name: selectedProjectName,
      };
      dispatch(startStatusLoader());
      dispatch({ type: FETCH_STATUS_CALCULATION_SAGA, payload: params });
      setSelectedJobNumber(null);
    }
  }, [dispatch, selectedProjectId, selectedProjectName]);

  useEffect(() => {
    if (tableLimitData?.length > 0) {
      const uniqueJobNumbers = getUniqueJobNumbers(tableLimitData);
      if (uniqueJobNumbers.length > 0) {
        setSelectedJobNumber(uniqueJobNumbers[0]);
      }
    }
  }, [tableLimitData]);

  const getRecordList = () => {
    let filteredData = selectedJobNumber
      ? tableLimitData?.filter((item) => item.job_number === selectedJobNumber)
      : tableLimitData;

    let passGroupOrder = [
      "Root",
      "Hot Pass",
      "Fill",
      "Last Fill",
      "Strip",
      "Cap",
    ];

    const normalizePassGroupName = (name) => {
      const lowerCaseName = name.toLowerCase();
      const normalizedPassGroup = passGroupOrder.find(
        (group) => group.toLowerCase() === lowerCaseName
      );
      return normalizedPassGroup || name;
    };

    let transformedData = filteredData?.reduce((acc, pass) => {
      const normalizedGroupName = normalizePassGroupName(pass.pass_group_name);
      const existingPass = acc.find(
        (p) => normalizePassGroupName(p.pass_group_name) === normalizedGroupName
      );

      if (existingPass) {
        existingPass.values = existingPass.values.concat({
          ...pass,
        });
      } else {
        acc.push({
          pass_group_name: normalizedGroupName,
          values: [
            {
              ...pass,
            },
          ],
        });
      }
      return acc;
    }, []);

    transformedData = transformedData?.sort((a, b) => {
      return (
        passGroupOrder.indexOf(a.pass_group_name) -
        passGroupOrder.indexOf(b.pass_group_name)
      );
    });

    let finalData = [];
    transformedData?.map((ite) => {
      finalData.push({
        ...ite,
        pass_group_name: ite.pass_group_name + " (Min)",
        original_pass_name: ite.pass_group_name,
        dataIndex: "min",
        key: "min",
      });
      finalData.push({
        ...ite,
        pass_group_name: ite.pass_group_name + " (Max)",
        original_pass_name: ite.pass_group_name,
        dataIndex: "max",
        key: "max",
      });
    });

    return finalData || [];
  };

  const transposedData = [
    {
      key: "current",
      label: "Current (A)",
      min: "current_min",
      max: "current_max",
    },
    { key: "volts", label: "Volts (V)", min: "volts_min", max: "volts_max" },
    {
      key: "wire_speed",
      label: "Wire Speed (in/min)",
      min: "wire_speed_min",
      max: "wire_speed_max",
    },
    {
      key: "travel_speed",
      label: "Travel Speed (in/min)",
      min: "travel_speed_min",
      max: "travel_speed_max",
    },
    {
      key: "oscillation",
      label: "Oscillation Width (in)",
      min: "oscillation_width_min",
      max: "oscillation_width_max",
    },
    {
      key: "calculated_heat",
      label: "Heat (KJ/in)",
      min: "calculated_heat_min",
      max: "calculated_heat_max",
    },
    {
      key: "heat",
      label: "True Energy (KJ/in)",
      min: "heat_min",
      max: "heat_max",
    },
  ];

  const getValue = (key, type, originalPassName) => {
    if (!tableLimitData || tableLimitData.length === 0) return null;

    let filteredData = tableLimitData?.filter(
      (ite) =>
        ite.pass_group_name === originalPassName &&
        (selectedJobNumber ? ite.job_number === selectedJobNumber : true)
    );

    let allKeyValues = filteredData
      .filter((item) => item[key])
      .map((item) => Number(item[key]));

    if (type === "min") {
      return allKeyValues?.length > 0 ? Math.min(...allKeyValues) : "";
    } else if (type === "max") {
      return allKeyValues?.length > 0 ? Math.max(...allKeyValues) : "";
    }
    return null;
  };

  const columns = [
    {
      title: "Parameter",
      dataIndex: "label",
      key: "label",
      render: (text) => (
        <div className="status-tb-title">
          <p>{text}</p>
        </div>
      ),
      width: 200,
      fixed: "left",
      className: "rowtitle",
    },

    ...getRecordList()?.map((ite) => {
      return {
        title: ite?.pass_group_name,
        dataIndex: ite?.dataIndex,
        key: ite?.key,
        width: 150,
        render: (text, record) => (
          <InputNumber
            className="no-arrows"
            style={{ width: "20%", minWidth: "120px", maxWidth: "150px" }}
            value={getValue(record[ite.key], ite?.key, ite?.original_pass_name)}
            onChange={(value) => handleInputChange(value, record.key, text)}
            disabled={true}
          />
        ),
      };
    }),
  ];

  const getUniqueJobNumbers = (data) => {
    const jobNumbers = data?.map((item) => item.job_number);
    return [...new Set(jobNumbers)].filter((jobNumber) => jobNumber !== null);
  };

  const hasJobNumbers = tableLimitData?.some((item) => item.job_number);
  return (
    <>
      {Loader ? (
        <div className="slogdata-loader">
          <CommonLoader />
        </div>
      ) : (
        <div className="status-cofig-table">
          {tableLimitData && tableLimitData.length > 0 && hasJobNumbers ? (
            <>
              <div className="status-table-limit">
                <span className="select-job-no">Select Job Number:</span>
                <Select
                  className="job-number-selector"
                  placeholder="Select job number"
                  style={{ width: 200 }}
                  value={selectedJobNumber}
                  onChange={(value) => setSelectedJobNumber(value)}
                >
                  {getUniqueJobNumbers(tableLimitData).map((jobNumber) => (
                    <Option key={jobNumber} value={jobNumber}>
                      {jobNumber}
                    </Option>
                  ))}
                </Select>
                <Table
                  columns={columns}
                  dataSource={transposedData}
                  pagination={false}
                  scroll={{ x: 800 }}
                  rowKey="key"
                />
                <div className="input-select-flex">
                  <div className="slop-time">
                    <span className="slop-span">
                      Slope Time (sec):
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In{" "}
                    </span>
                    <InputNumber
                      defaultValue={0}
                      className="slop-input"
                      disabled={true}
                    />
                    <span className="slop-span"> Out </span>
                    <InputNumber
                      defaultValue={0}
                      className="slop-input"
                      disabled={true}
                    />
                  </div>
                  <div className="slop-time">
                    <span className="slop-span">
                      Status Calculation Method:{" "}
                    </span>
                    <Select
                      defaultValue="Instantaneous"
                      className="status-selector"
                      disabled={true}
                    >
                      <Option value="Instantaneous">Instantaneous</Option>
                      <Option value="Average">Average</Option>
                    </Select>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="slog-NoData">
              <p>
                Status configuration details are not available for this project.
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default StatusConfiguration;
